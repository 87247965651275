<template>
    <div class="navigation relative w-full h-full">
        <button v-if="isLeftArrowDisplayed" class="navigation__arrow navigation__arrow--left absolute left-0 flex justify-center items-center md:hidden w-15 h-15 ml-7 bg-white shadow-lg rounded-full" @click="arrowLeftClickHandler">
            left
        </button>
        <button v-if="isRightArrowDisplayed" class="navigation__arrow navigation__arrow--right absolute right-0 flex justify-center items-center md:hidden w-15 h-15 mr-7 bg-white shadow-lg rounded-full" @click="arrowRightClickHandler">
            right
        </button>
        <div ref="scrollableContent" class="overflow-x-scroll md:overflow-visible bg-gray-100 py-10 md:py-15" @scroll="navScrollHandler">
            <div
                ref="links"
                class="flex md:justify-center md:flex-wrap"
            >
                <a
                    v-for="(link, key) in links"
                    :key="key"
                    :class="{'last-link': (key === links.length-1)}"
                    :href="`#${link.anchor}`"
                    class="navigation__link inline-flex flex-shrink-0 flex-col items-center px-6 font-montserrat-bold text-black text-lg leading-normal"
                    @click.prevent="linkClickHandler"
                    v-html="link.title"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {TweenLite, Power4} from 'gsap/all';

    export default {
        name: 'Navigation',

        props: {
            links: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                isLeftArrowDisplayed: false,
                isRightArrowDisplayed: true,
            }
        },

        methods: {
            linkClickHandler(event) {
                TweenLite.to(window, 2, {
                    scrollTo: {
                        y: event.currentTarget.getAttribute('href'),
                        autoKill: false
                    },
                    ease: Power4.easeInOut
                });
            },

            arrowLeftClickHandler() {
                TweenLite.to(this.$refs.scrollableContent, 1, {
                    scrollTo: {
                        x: 0,
                        autoKill: false
                    },
                    ease: Power4.easeInOut
                });
            },

            arrowRightClickHandler() {
                TweenLite.to(this.$refs.scrollableContent, 1, {
                    scrollTo: {
                        x: document.querySelector('.last-link'),
                        autoKill: false
                    },
                    ease: Power4.easeInOut
                });
            },

            navScrollHandler(event) {
                this.isLeftArrowDisplayed = true;
                this.isRightArrowDisplayed = true;

                if(event.target.scrollLeft === 0) {
                    this.isLeftArrowDisplayed = false;
                }

                if(event.target.scrollLeft === (event.target.scrollWidth - window.innerWidth)) {
                    this.isRightArrowDisplayed = false;
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    @import '../../assets/styles/abstracts/mixins';

    .navigation {
        &__arrow {
            top: 50%;

            width: 3rem;
            height: 3rem;

            margin-top: -1.5rem;

            font-size: 0;

            &--left {
                &::after {
                    @include size(11px, 6px);
                    content: '';
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAOCAYAAAArMezNAAAABGdBTUEAALGPC/xhBQAAAIdJREFUOBG109EJgDAMBNDgh1/u4srO4DQdwh30LhioipomWjiKaF4KpiI/rxH+hPTJPqynQ086pCArMiNRnHWsp0NPFzssSBSvUTp6YpX3hwj+iEZxF9qKN6FePIS+4Sn0Dh/wwkbq8vetyLvXo2hTk0at+Rk/zKl9FN2J8UZ9itpheP1dawOegjfUcKZ8/wAAAABJRU5ErkJggg==);
                    background-size: cover;

                    transform: rotate(90deg);
                }
            }

            &--right {
                &::after {
                    @include size(11px, 6px);
                    content: '';
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAOCAYAAAArMezNAAAABGdBTUEAALGPC/xhBQAAAIdJREFUOBG109EJgDAMBNDgh1/u4srO4DQdwh30LhioipomWjiKaF4KpiI/rxH+hPTJPqynQ086pCArMiNRnHWsp0NPFzssSBSvUTp6YpX3hwj+iEZxF9qKN6FePIS+4Sn0Dh/wwkbq8vetyLvXo2hTk0at+Rk/zKl9FN2J8UZ9itpheP1dawOegjfUcKZ8/wAAAABJRU5ErkJggg==);
                    background-size: cover;

                    transform: rotate(-90deg);
                }
            }
        }

        &__link {
            &::after {
                @include size(11px, 6px);
                content: '';
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAOCAYAAAArMezNAAAABGdBTUEAALGPC/xhBQAAAIdJREFUOBG109EJgDAMBNDgh1/u4srO4DQdwh30LhioipomWjiKaF4KpiI/rxH+hPTJPqynQ086pCArMiNRnHWsp0NPFzssSBSvUTp6YpX3hwj+iEZxF9qKN6FePIS+4Sn0Dh/wwkbq8vetyLvXo2hTk0at+Rk/zKl9FN2J8UZ9itpheP1dawOegjfUcKZ8/wAAAABJRU5ErkJggg==);
                background-size: cover;
            }
        }
    }
</style>
