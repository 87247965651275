<template>
    <a v-if="link" :href="link">
        <slot />
    </a>
    <div v-else>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'ConditionalLink',
        props: {
            link: {
                type: String,
                required: true,
            },
        },
    }
</script>
