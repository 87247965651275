<template>
    <intersect :threshold="[0.5]" @enter="playIn">
        <div class="half-content relative overflow-hidden">
            <slot name="background" />
            <div class="container grid-container">
                <div
                    :class="{'flex-col-reverse md:flex-row-reverse': reverse}"
                    class="grid-row justify-between flex-wrap"
                >
                    <div ref="textContent" class="half-content__block w-full md:w-1/2 grid-col">
                        <div class="flex flex-col justify-center h-full py-20">
                            <slot name="text" />
                            <slot name="button" />
                        </div>
                    </div>
                    <div ref="mediaContent" class="half-content__block w-full md:w-1/2 grid-col">
                        <div class="relative flex justify-center items-center h-full">
                            <slot name="form" />

                            <template v-if="videoUrl && !hasForm">
                                <video-player :video-url="videoUrl" :poster-url="imageUrl" />
                            </template>
                            <img v-else-if="imageUrl && !hasForm" :src="imageUrl" alt="Half content image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </intersect>
</template>

<script>
    import Intersect from 'vue-intersect';
    import {TimelineLite, Power4} from 'gsap/all';
    import VideoPlayer from './VideoPlayer';

    export default {
        name: "HalfContent",

        components: {
            Intersect,
            VideoPlayer,
        },

        props: {
            reverse: {
                type: Boolean,
                default: false
            },
            imageUrl: {
                type: String,
                default: null
            },
            videoUrl: {
                type: String,
                default: null
            },
            hasForm: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                isAnimationDone: false,
            }
        },

        methods: {
            playIn() {
                if(this.isAnimationDone) {
                    return;
                }

                this.isAnimationDone = true;

                let tl = new TimelineLite();

                tl.from(this.$refs.textContent, 0.7, {
                    x: -80,
                    ease: Power4.easeInOut,
                }, 0);

                tl.to(this.$refs.textContent, 0.7, {
                    opacity: 1,
                    ease: Power4.easeInOut,
                }, 0);

                tl.from(this.$refs.mediaContent, 0.7, {
                    x: -80,
                    ease: Power4.easeInOut,
                }, 0.35);

                tl.to(this.$refs.mediaContent, 0.7, {
                    opacity: 1,
                    ease: Power4.easeInOut,
                }, 0.35);
            }
        }
    }
</script>

<style lang="scss">
    .half-content {
        &__background-media {
            top: 0;
            left: 50%;

            transform: translateX(-50%);
        }

        &__block {
            opacity: 0;
        }

        &__text-content {
            *:not(figure) {
                @apply my-2;
            }

            .caption {
                @apply mt-8;
            }

            figure {
                @apply inline-block mr-5;
            }

            &--light {
                * {
                    @apply text-white;
                }
            }

            &--dark {
                * {
                    @apply text-black;
                }

                h1,
                h2,
                h3,
                h4 {
                    @apply text-primary;
                }
            }
        }
    }
</style>
