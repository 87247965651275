<template>
    <div
        :class="{
            'collapse-panel--opened': isOpened,
            'collapse-panel--light': textColor === 'light',
            'collapse-panel--dark': textColor === 'dark',
        }"
        class="collapse-panel flex flex-col items-center px-6"
        :style="`background-color: ${backgroundColor}`"
    >
        <button
            class="collapse-panel__button relative flex items-center py-10 font-roboto-bold text-base normal-case text-center focus:outline-none"
            data-gtm-cta="1"
            @click="togglePanel"
        >
            <img
                v-if="picto !== ''"
                :src="picto"
                class="collapse-panel__picto mr-3"
                alt="Liste picto"
            >
            {{ heading }}
        </button>

        <div ref="content" class="collapse-panel__content overflow-hidden container grid-container">
            <div class="grid-row">
                <div class="w-full grid-col">
                    <div class="collapse-panel__inner pb-10 text-center" v-html="hiddenContent" />
                    <button class="collapse-panel__close-button mx-auto flex flex-row items-center mb-20" @click="togglePanel">
                        Fermer
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {TweenLite, Power4} from 'gsap/all';

    export default {
        name: 'CollapsePanel',

        props: {
            backgroundColor: {
                type: String,
                default: '#fff'
            },
            picto: {
                type: String,
                default: '',
            },
            heading: {
                type: String,
                default: '',
            },
            hiddenContent: {
                type: String,
                default: '',
            },
            textColor: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                isOpened: false,
            }
        },

        methods: {
            togglePanel() {
                if(!this.isOpened) {
                    this.isOpened = true;

                    TweenLite.set(this.$refs.content, {
                        height: 'auto',
                    });

                    TweenLite.from(this.$refs.content, 0.5, {
                        height: 0,
                        ease: Power4.easeInOut,
                    });
                }
                else {
                    this.isOpened = false;

                    TweenLite.to(this.$refs.content, 0.5, {
                        height: 0,
                        ease: Power4.easeInOut,
                    });
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../assets/styles/abstracts/mixins';

    .collapse-panel {
        &__button{
            &::after {
                content: '';
                display: block;
                width: 0.75rem;
                height: 0.5rem;
                margin-left: 0.625rem;

                transform: rotate(180deg);

                background-size: cover;

                transition: transform 200ms ease-in-out;
            }
        }

        &__picto {
            width: 3rem;
            height: 3rem;
        }

        &__content {
            height: 0;
        }

        &__close-button {
            border: 1px solid;
            border-radius: 26px;
            padding: 10px 20px;

            &::before {
                content: '';
                display: block;
                width: 0.75rem;
                height: 0.75rem;

                margin-right: 0.5rem;

                background-size: cover;
            }
        }

        &--opened {
            .collapse-panel__button {
                &::after {
                    transform: rotate(0);
                }
            }
        }

        &--light {
            .collapse-panel__button {
                @apply text-white;

                &::after {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAABGdBTUEAALGPC/xhBQAAALVJREFUOBFjYBhM4P///9lA7EwTNwENzgNiEPgGxC5UtQRoIMxwkAUgALKEOj4BGoRuOMgCEKDcEqAhuAwH2wC1hLzgwmH4T5jJSDTpPsFhONggfHJERTwxBhCjBqtlpGgkRS3YMpI1AHURrYdohVj8TVAvUIE2EP8DYmRAUsoAasSWnF8BxblhwZMO5MAsIclwmKfQLPkC5NvB5JAt+QqUILsIgFryGcNwmE1ACUkYm1wa3QwAgHAeCpjJ47cAAAAASUVORK5CYII=);
                }
            }

            .collapse-panel__close-button {
                @apply text-white;

                &::before {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAAPRJREFUSA2tlU0KwjAQRos3cNGNV3DnzrMLLks3LgQRETyJQn1f7WAQ2/xMBqaZNPpeGpq0GYZhSx7JtqkUsNbkgdw1XARXXEi3BIbgJ1LRS9CSgitcEv4fwu/0N+OiULgls3Bbdo8kCvdIkuElkmx4jqQYniJxw5ck1eAzkiuCM6n4vuf249IWmPaJ4BYPis8mikBXkXEbflE8rTPVYT8YyiyZabj9NfPb9BiuY2Wcxg98XHPuuY+VWbg9vFvyb+YGt7ZYkgIvluTAsyUl8GSJBx6V1IAvShDo66+ocrbACfdJp4/+juzJpLPFZrvUTpKOdv8GgvTvX6cWKEoAAAAASUVORK5CYII=);
                }
            }

            .collapse-panel__inner {
                @apply text-white;
            }
        }

        &--dark {
            .collapse-panel__button {
                @apply text-black;

                &::after {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAOCAYAAAArMezNAAAABGdBTUEAALGPC/xhBQAAAIBJREFUOBG109EJwCAMBFDpR7+6S1d2hk7jEN2hvQsVRCieaRo48pM8BDUlvRZ9VJ/cMVoQ9rAidiLX00PwHg3BW/TAaTeE/RPeoytAFrsbf0NN9uIj1IWr6BQ+i0q4Fx3i/FF8Qrztevt1Se3ta6Fn358nzogXxaoV9zNC77+6AYiNN9QcFyCqAAAAAElFTkSuQmCC);
                }
            }

            .collapse-panel__close-button {
                @apply text-black;

                &::before {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAALZJREFUOBG1ld0NgCAMhIlr+Or+KziIw2ivCQmS0l4FSRr+7r4WoqGUUg6JU2KXmG1ggAWmDm7pL4kZOLxggAW4wuoCegiyDR6TMdwgMoTeUGAkoT20UJJktFoTY2A0xgH9aj5DayYLYK1VfarvQZe4V3zzWkQLp6Fbqv6F4rZaXMOSq+ihmFtrqXN4AG/PTcIYGc0rScZAa2lhU0roCQUNrB8OvcONnuDMTQaeEfqPIuFg/vOYPibXb6nmHhqDAAAAAElFTkSuQmCC);
                }
            }

            .collapse-panel__inner {
                @apply text-black;
            }
        }
    }
</style>
