<template>
    <div :style="`background-color: ${backgroundColor}`" class="video-carousel">
        <transition name="fade" mode="in-out">
            <template v-for="(slide, key) in slides">
                <div
                    v-if="currentSlide === key"
                    :key="key"
                    class="video-carousel__background absolute pin w-full h-full bg-cover"
                    :style="getCurrentSlideBackground(slide)"
                />
            </template>
        </transition>

        <div :class="{'py-32': nbSlides > 1, 'py-46': nbSlides === 1}" class="relative container flex flex-wrap justify-center mx-auto">
            <div class="w-5/6">
                <slick ref="slick" :options="slickOptions" @beforeChange="beforeChangeHandler">
                    <div v-for="(slide, key) in slides" :key="key" class="relative">
                        <video-player :ref="`videoPlayer-${key}`" :video-url="slide.videoUrl" :poster-url="slide.videoPoster" />
                    </div>
                </slick>
            </div>
            <div v-if="isNavigationEnabled" class="w-5/6 flex justify-center mt-9">
                <button class="video-carousel__arrow video-carousel__arrow--prev focus:outline-none" @click="prev()" />
                <div class="video-carousel__folio flex items-center mx-10">
                    <button
                        v-for="(index, key) in nbSlides"
                        :key="key"
                        :class="{'video-carousel__folio-item--current': currentSlide === key}"
                        class="video-carousel__folio-item rounded-full focus:outline-none"
                        @click="updateSlide(key)"
                    />
                </div>
                <button class="video-carousel__arrow video-carousel__arrow--next focus:outline-none" @click="next()" />
            </div>
        </div>
    </div>
</template>

<script>
    import Slick from 'vue-slick';
    import VideoPlayer from './VideoPlayer';

    export default {
        name: 'VideoCarousel',

        components: {
            Slick,
            VideoPlayer,
        },

        props: {
            backgroundColor: {
                type: String,
                default: '#fff'
            },
            slides: {
                type: Array,
                required: true,
            }
        },

        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    fade: true,
                    arrows: false,
                },
                currentSlide: 0,
                isPosterVisible: true,
            }
        },

        computed: {
            nbSlides() {
                return this.slides.length;
            },

            isNavigationEnabled() {
                return this.nbSlides > 1;
            }
        },

        methods: {
            updateSlide(key) {
                this.$refs.slick.goTo(key);
            },

            next() {
                this.$refs.slick.next();
            },

            prev() {
                this.$refs.slick.prev();
            },

            beforeChangeHandler(event, slick, currentSlide, nextSlide) {
                this.$refs['videoPlayer-' + currentSlide][0].pauseVideo();
                this.currentSlide = nextSlide;
            },

            getCurrentSlideBackground(slide) {
                if (slide.backgroundImage) {
                    return `background-image: url(${slide.backgroundImage})`;
                }
                else if (slide.backgroundColor) {
                    return `background-color: ${slide.backgroundColor}`;
                }
                else {
                    return null;
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .video-carousel {
        position: relative;

        &__background {
            position: absolute;
        }

        &__video {
            &:focus {
                outline: none;
            }
        }

        &__arrow {
            width: 13px;
            height: 22px;

            &--next {
                background-size: cover;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAsCAYAAAB7aah+AAAABGdBTUEAALGPC/xhBQAAAMpJREFUWAntlbENwjAQRdPACmyAqNmCkpqBqFkkDbswRgagMQ90li6Jg3KSTYG+pdPZVnxPeTrZXWcjpbQh9nndJBukJw/EsTUExme0gVH6YgCfmsGunmJzwWI9hDZpjCkrfS2NJSvhPWkMKysdkMaSlfDeX2u88XfT8X48D2FNSwcotiMeUwrrO7FdOhfap5Ag641J13pXfCld0kUPjIburnlPIEi38FzLtx2UnYjnqLdqvoweDuTsYPVa2EPy3GA9uc4bnwv/Or8AaddrzpVjWB0AAAAASUVORK5CYII=);
            }

            &--prev {
                background-size: cover;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAsCAYAAAB7aah+AAAABGdBTUEAALGPC/xhBQAAAM1JREFUWAljYBjK4P///2xAvA6IA2nmD6glm4E0CPwCYupbBjQU5BOYJUAmGIAs86Saz4CGYbMEZNM1IBanikWjlpAUjKPBNRpcwDSAAkYzI2aaAAbQaNmFGSy4REaDC1fIYBNnggoqAWlrLAp2MzIyvsQiTr4QMH6MgPgdEKODdvJNxaETaAMuy9pwaCFfeLBYRtdgHLWM+ASDJ4GMBuNoMDIwjCYQ4lMBHpUjMhij8QQIeVJYghE03sBKnmkEdCFZRjtLYG4AWqaG7BMAzYlTRFwEvRwAAAAASUVORK5CYII=);
            }
        }

        &__folio-item {
            width: 0.5rem;
            height: 0.5rem;

            margin: 0 5px;

            @apply bg-white;

            &--current {
                @apply bg-black;
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
