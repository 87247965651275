<template>
    <div
        ref="container"
        :data-gtm-container-name="productName"
        class="product-hero"
        data-gtm-container-type="ProductBig"
    >
        <conditional-link
            :link="productLink"
            :class="{'md:flex-row-reverse': reverse, 'md:flex-row': !reverse}"
            class="product-hero__container container flex flex-col justify-center items-center text-black hover:text-black"
        >
            <img
                ref="image"
                :src="productImage"
                :alt="'Image' + productName"
                :class="{'md:ml-10 lg:ml-60': reverse, 'md:mr-10 lg:mr-60': !reverse}"
                class="product-hero__product-image lazyload mb-10 md:mb-0"
            >
            <div
                ref="text"
                class="product-hero__product-text text-center md:text-left mb-20 md:my-30"
            >
                <div v-if="productInfo" class="product-hero__label inline-flex badge badge--blue">
                    {{ productInfo }}
                </div>
                <div class="product-hero__product-categories mt-15 body-1">
                    {{ productCategory }}
                </div>
                <div class="product-hero__product-name font-roboto-bold text-xl leading-normal text-primary">
                    {{ productName }}
                </div>
                <p v-if="price !== ''" class="pt-8 flex items-end justify-center md:justify-start">
                    <span class="font-roboto-regular text-xs">{{ priceCaption }}</span>
                    <span class="h2">&nbsp;{{ price }}</span>
                </p>
                <p v-if="priceBaseline !== ''" class="product-hero__price-baseline pt-8 font-roboto-regular text-xs text-secondary">
                    {{ priceBaseline }}
                </p>
                <div v-if="productLink" class="product-hero__button mt-7">
                    <div :class="gtmTagName" class="call-to-action call-to-action--blue">
                        Acheter
                    </div>
                </div>
            </div>
        </conditional-link>
    </div>
</template>

<script>
    import {TimelineLite, Power3} from 'gsap/all';
    import imagesLoaded from 'imagesloaded';
    import ConditionalLink from './ConditionalLink';

    export default {
        name: 'ProductHero',

        components: {
            ConditionalLink,
        },

        props: {
            reverse: {
                type: Boolean,
                default: false,
            },
            productName: {
                type: String,
                required: true,
            },
            productCategory: {
                type: String,
                default: null,
            },
            productLink: {
                type: String,
                required: true,
            },
            productInfo: {
                type: String,
                default: null,
            },
            productImage: {
                type: String,
                default: null,
            },
            price: {
                type: String,
                default: null,
            },
            priceCaption: {
                type: String,
                default: null,
            },
            priceBaseline: {
                type: String,
                default: null,
            },
            gtmTagName: {
                type: String,
                default: null,
            },
        },

        mounted() {
            let timeline = new TimelineLite({
                paused: true,
            });

            timeline.from(this.$refs.image, 1.2, {
                y: '+=100px',
                autoAlpha: 0,
                ease: Power3.easeOut
            });

            for (let node of this.$refs.text.childNodes) {
                timeline.from(node, 0.8, {
                    x: node.offsetWidth / 3,
                    autoAlpha: 0,
                    ease: Power3.easeOut
                }, '-=0.7')
            }

            imagesLoaded(this.$refs.image, () => {
                timeline.play();
            })
        }
    }
</script>

<style scoped lang="scss">
    @import '../../assets/styles/abstracts/mixins';

    .product-hero {
        &__product-image {
            position: relative;
            width: 260px;
            height: 211px;
            margin-top: -78px;

            visibility: hidden;

            @screen md {
                width: 360px;
                height: 292px;
                margin-top: 38px;
            }

            @screen md {
                width: 555px;
                height: 450px;
                margin-top: -120px;
            }
        }

        &__product-text > * {
            visibility: hidden;
        }

        &__price-baseline {
            max-width: 325px;
        }
    }
</style>
