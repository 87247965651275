<template>
    <div>
        <slot />
        <button class="masthead__scroll-button absolute uppercase" @click="scrollButtonCLickHandler">
            {{ scrollButtonWording }}
        </button>
    </div>
</template>

<script>
    import {TweenLite, Power4} from 'gsap/all';

    export default {
        name: "Masthead",

        props: {
            scrollButtonWording: {
                type: String,
                required: true
            }
        },

        methods: {
            scrollButtonCLickHandler() {
                TweenLite.to(window, 2, {
                    scrollTo: {
                        y: (window.scrollY + this.$el.offsetHeight),
                        autoKill: false
                    },
                    ease: Power4.easeInOut
                });
            }
        }
    }
</script>
