<template>
    <div class="video-player relative">
        <video
            ref="video"
            class="block w-full"
            controls
        >
            <source :src="videoUrl" type="video/mp4">
        </video>
        <div
            v-if="isPosterVisible && posterUrl"
            class="video-player__poster absolute inset-0 bg-cover bg-center"
            :style="`background-image: url(${posterUrl})`"
            @click="playVideo"
        />
    </div>
</template>

<script>
    export default {
        name: "VideoPlayer",

        props: {
            videoUrl: {
                type: String,
                required: true,
            },
            posterUrl: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                isPosterVisible: true,
            }
        },

        methods: {
            playVideo() {
                this.isPosterVisible = false;
                this.$refs.video.play();
            },

            pauseVideo() {
                this.$refs.video.pause();
            }
        }
    }
</script>

<style scoped lang="scss">
    .video-player {
        &__poster {
            &::before {
                @apply absolute w-46 h-46 bg-white rounded-full cursor-pointer;
                content: '';
                top: 50%;
                left: 50%;

                opacity: 0.3;

                transform: translate(-50%, -50%);

                @screen md {
                    @apply w-82 h-82;
                }
            }

            &::after {
                @apply absolute bg-cover bg-center cursor-pointer ml-1;
                content: '';
                top: 50%;
                left: 50%;
                width: 22px;
                height: 28px;

                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABQCAYAAAEyzSbFAAAABGdBTUEAALGPC/xhBQAAA+lJREFUeAHlmmly2zAMhZ1Oj1DnYOk0uUN7siY9Wew7uIRGYB8oLuAqidUfQCSAh49aLFu+PB6P35fYZgKWLRTzhSfWuE21J5rgILZPZmPfVuABsmu1d/K9FWiCt6/suJZlvBI8SUmiAk5wRVvBN8lBYRtaHMoQeG55q0uRuCibTBrgjaqIsjzBVpTlQbKUKBaDBzlIZFI0TyyWOhQDuLO2Hw7AYMf30mzknSTe9SbzJNlYoWRyrFBWsltILDVOJvw7zW8OYiTpbvivOJ9KvpmEZ0wQPh9nsJ8iILazJukTYsWy5lblzQ1MU0Qc59jZ5CsmjnNuF0IZq2u6EMqYvHax3H5xHP2gsggybeA++0FlDiAb6kKljIVwLVTKmIxdZCtDoXvqqoLYfy63nts2XdN25dXKmMQ9aJSFGieSjSr71DA5pEy3H8uGCehvlDVJXACVVWqcuFg6Y8RAzs56upH5lpNXHQvC6HZvQnNNXc3BWz7WqimhgEYYwi/NmsgVxiaezUrccCDHrxFGnewmWglnN9FDWNVEb+FgEyOFRRN4z8SJnv4bXQ2bO30nRRITT0Q9hTdiAgpv0A38V1E8tjNUDBspFP6BNYr8DOF6MewwIdxWLCHcT8wjPEbMEe7+mIN61l+PcdF3TlukxHFOrnH0jjDtjqH3CPNQV/rYx+KtJ732QaDZYy2fhzFijiHbnF5LjE00odcSozDRi6cJnNT6JcRYO/tBnpNLiDmX7GcpfS0xNpFFX0uMwln0LYmxiSR9S2IUTtL3IsYmvPS9iFHYSz+CGJuw9COIUdjSjybmJt56fmljEbT25crIpaZvj1fuYgSxpWRRsr2JBSUK9yL2UqJwD+IgJQq3JE5SonArYhUlCtcSZ1GicA3xK16XWFTjlxDH39RqVE1MLjFRhl8PK0UpTEvchBL70hA3o0ThGHFzShTml638nZht/x9jWGm14978g3B/SlxrIzyO0hEmaHoIe8HxaX2idTaC//4/ASP/fPBIl/DngE9AhqbPCx8iyhg/F3wGmCb0+PAaisKYY8IXwuSmHQZ+jx976E8yP80XkT97fNbvAYycw+H3Bh4OfyTgIfBHBe4GfwbgpvBnA66GPzNwEfwswC78L/M5/4GD7M8IzGxk6XNewM8OvIGP/WCKwWf36TGWHmeH/dt1jwWzkCg+2xH2Qs4GnIScATgL8qzAxZBnAm4CeXTg5pBHBO4KeRTgYZB7Au8CicChV+K5P8PG4j/MpP2jlhDfYyfWacXcsSBxYSug3NTjQjYEPgdkJfC7ObTHuSYRRuO752Vg/9yQuBABQBqeBzICPCekAzw/JAD/BQMjYpfB+ZeJAAAAAElFTkSuQmCC);

                transform: translate(-50%, -50%);

                @screen md {
                    @apply w-12 h-20;
                }
            }
        }
    }
</style>
