<template>
    <div
        :class="{'md:justify-end lg:justify-start lg:flex-row-reverse': reverse}"
        class="technology"
    >
        <div class="technology__content">
            <h3>
                {{ title }}
            </h3>
            <div class="technology__description" v-html="body" />

            <div class="technology__features-container">
                <div class="technology__nav">
                    <button
                        v-for="(feature, key) in features"
                        :key="key"
                        :style="getCurrentPicto(feature, key)"
                        class="technology__picto w-32 h-32 mr-3 bg-cover focus:outline-none"
                        @click="updateCurrentFeature(key)"
                    />
                </div>
                <transition name="text-fade" mode="out-in">
                    <template v-for="(feature, key) in features">
                        <div v-if="currentFeature === key" :key="key" class="technology__feature-content mt-9">
                            <div
                                class="technology__feature-description mt-1 text-sm text-black"
                                v-html="feature.description"
                            />
                        </div>
                    </template>
                </transition>
            </div>
        </div>
        <div class="technology__image-container">
            <transition name="image-fade" mode="in-out">
                <template v-for="(feature, key) in features">
                    <div v-if="currentFeature === key" :key="key" class="technology__image-inner">
                        <picture>
                            <source
                                media="(max-width: 767px)"
                                :data-srcset="feature.imageMobile"
                            >
                            <source
                                media="(min-width: 768px) and (max-width: 1199px)"
                                :data-srcset="feature.imageTablet"
                            >
                            <source
                                media="(min-width: 1200px)"
                                :data-srcset="feature.imageDesktop"
                            >
                            <img
                                class="technology__image lazyload"
                                :src="feature.imageLazy"
                                alt=""
                            >
                        </picture>
                    </div>
                </template>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Technology',

        props: {
            reverse: {
                type: Boolean,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            body: {
                type: String,
                default: '',
            },
            features: {
                type: Array,
                required: true,
            }
        },

        data() {
            return {
                currentFeature: 0,
                intervalId: null,
            }
        },

        mounted() {
            this.intervalId = setInterval(this.incrementCurrentFeature.bind(this), 7000);
        },

        methods: {
            updateCurrentFeature(key) {
                clearInterval(this.intervalId);
                this.currentFeature = key;
            },

            getCurrentPicto(feature, key) {
                if (this.currentFeature === key) {
                    return `background-image: url(${feature.pictoActive})`;
                }

                return `background-image: url(${feature.picto})`;
            },

            incrementCurrentFeature() {
                if (this.currentFeature === this.features.length - 1) {
                    this.currentFeature = 0;
                }
                else {
                    this.currentFeature++;
                }
            },
        }
    }
</script>

<style lang="scss">
    .technology {

        &__content {
            min-height: 28.75rem;

            @screen lg {
                min-height: 31.25rem;
            }
        }

        &__description {
            p {
                @apply font-montserrat-regular text-sm leading-normal;
            }

            @screen md {
                p {
                    @apply text-lg;
                }
            }
        }

        &__picto {
            transition: transform 0.2s ease-in-out;

            &:hover {
                transform: scale(1.15);
            }
        }

        &__feature-description {
            p {
                strong {
                    @apply text-sm block;
                    margin: 0.625rem 0 0.25rem 0;
                }
            }
        }

        &__image {
            max-width: none;
        }
    }

    .text-fade-enter-active, .text-fade-leave-active {
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-out;
    }

    .text-fade-enter, .text-fade-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }

    .image-fade-enter-active, .image-fade-leave-active {
        transition: opacity 0.6s ease-in-out, transform 0.6s ease-out;
    }

    .image-fade-enter {
        opacity: 0;
        transform: translateX(-80px);
    }

    .image-fade-leave-to {
        opacity: 0;
    }
</style>
