<template>
    <button class="anchor" @click="buttonClickHandler">
        <slot />
    </button>
</template>

<script>
    import {TweenLite, Power4} from 'gsap/all';

    export default {
        name: "Anchor",

        props: {
            target: {
                type: String,
                default: '#'
            }
        },

        methods: {
            buttonClickHandler() {
                TweenLite.to(window, 2, {
                    scrollTo: {
                        y: this.target,
                        autoKill: false
                    },
                    ease: Power4.easeInOut
                });
            }
        }
    }
</script>
