// Import des styles principaux.
import './assets/styles/main.scss';

/* eslint-disable */
// Vue.js
import Vue from 'vue';

// GSAP
import {CSSPlugin, ScrollToPlugin} from 'gsap/all';
const plugins = [CSSPlugin, ScrollToPlugin];
/* eslint-enable */

import 'lazysizes';

// Intersect Polyfill
import 'intersection-observer';

// Import des composants Vue.js

import ProductHero from './app/components/ProductHero';
import Navigation from './app/components/Navigation';
import VideoCarousel from './app/components/VideoCarousel';
import Technology from './app/components/Technology';
import CollapsePanel from './app/components/CollapsePanel';
import ConditionalLink from './app/components/ConditionalLink';
import HalfContent from './app/components/HalfContent';
import Masthead from './app/components/Masthead';
import Anchor from './app/components/Anchor';


// Instanciate the vue

new Vue({
    el: '#app',
    components: {
        ProductHero,
        Navigation,
        VideoCarousel,
        Technology,
        CollapsePanel,
        ConditionalLink,
        HalfContent,
        Masthead,
        Anchor,
    }
});
